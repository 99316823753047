import {
  Analytics,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LiveHelp,
  School,
  Settings,
} from "@mui/icons-material";
import { IconButton } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { useState } from "react";
import { HelpCenterModal } from "../util/HelpCenterModal";
import { ExpandedSidebar } from "./sidebarStates/ExpandedSidebar";
import { CollapsedSidebar } from "./sidebarStates/CollapsedSidebar";
import { useGuide } from "../onboarding/useGuide";
import { useMe } from "../../lib/api/user";
import { useOrganization } from "../../lib/api/organization";
import { type Path } from "../../router";
import { useTranslation } from "react-i18next";
import { SIDEBAR_TOGGLE_BUTTON_ID } from "../../lib/testIds";
import { SIDEBAR_SETTINGS_BUTTON_ID } from "../../lib/testIds";

export interface BottomButton {
  icon: JSX.Element;
  text: string;
  doesRender: boolean;
  url?: Path;
  onClick?: () => void;
  testId?: string;
}

export function Sidebar({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (o: boolean) => void;
}) {
  const [openHelpCenter, setOpenHelpCenter] = useState(false);
  const { setStep, step, completed } = useGuide();
  const me = useMe();

  const { t } = useTranslation();

  function makeTourStep() {
    if (!completed) {
      setStep(step + 1);
    }
  }
  const organization = useOrganization();
  const isOrganizationAdmin = !!me?.isOrganizationAdmin;

  const bottomButtons: BottomButton[] = [
    {
      icon: <School />,
      text: t("e-learning"),
      doesRender: !!organization?.defaultWorkshopId,
      url: "/:organizationId/learn/:workshopId",
    },
    {
      icon: <Analytics />,
      text: t("settings.tabs.metrics"),
      doesRender: isOrganizationAdmin,
      url: "/:organizationId/adoption",
    },
    {
      icon: <Settings />,
      text: t("einstellungen"),
      doesRender: isOrganizationAdmin,
      url: "/:organizationId/settings",
      testId: SIDEBAR_SETTINGS_BUTTON_ID,
    },
    {
      icon: <LiveHelp />,
      doesRender: true,
      text: t("helpAndFeedback"),
      onClick: () => {
        setOpenHelpCenter(true);
      },
    },
  ];
  return (
    <Sheet
      className="Sidebar"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 20,
        position: "sticky",
        height: "100dvh",
        width: sidebarOpen ? "320px" : "60px",
        top: 0,
        py: 1,
        flexShrink: 0,
        borderRight: "1px solid",
        borderColor: "divider",
        transition: "width 0.2s",
      }}
      id="sidebar"
    >
      <HelpCenterModal open={openHelpCenter} setOpen={setOpenHelpCenter} />
      <IconButton
        data-testid={SIDEBAR_TOGGLE_BUTTON_ID}
        style={sidebarOpen ? { position: "absolute", right: 2, top: 5 } : {}}
        onClick={() => {
          setSidebarOpen(!sidebarOpen);
        }}
      >
        {sidebarOpen ? (
          <KeyboardDoubleArrowLeft />
        ) : (
          <KeyboardDoubleArrowRight />
        )}
      </IconButton>

      {sidebarOpen ? (
        <ExpandedSidebar
          makeTourStep={makeTourStep}
          bottomButtons={bottomButtons}
        />
      ) : (
        <CollapsedSidebar
          makeTourStep={makeTourStep}
          bottomButtons={bottomButtons}
        />
      )}
    </Sheet>
  );
}
