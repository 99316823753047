import "highlight.js/styles/github.css";
import type { ComponentProps, CSSProperties } from "react";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import remarkBreaks from "remark-breaks";
import remarkDirective from "remark-directive";
import RemarkDirectiveRehype from "remark-directive-rehype";
import remarkGfm from "remark-gfm";
import { CodeBlock } from "./markdown/CodeBlock.tsx";
import { InlineCitation } from "./markdown/InlineCitation.tsx";
import RemarkCitePlugin from "./markdown/RemarkCitePlugin.ts";
import type { RagSource } from "../../../../backend/src/api/chat/message/messageTypes.ts";

export interface HighlightInfo {
  x: number;
  y: number;
  text: string;
  context: string | null;
}

interface MarkdownRendererProps {
  content: string;
  style?: CSSProperties;
  sources?: RagSource[];
  onHighlight?: (data: HighlightInfo | null) => void;
}

const remarkPlugins = [
  remarkDirective,
  remarkGfm,
  remarkBreaks,
  RemarkCitePlugin,
];
const rehypePlugins = [
  [
    RehypeHighlight,
    {
      ignoreMissing: true,
      detect: true,
    },
  ],
  RemarkDirectiveRehype,
] as ComponentProps<typeof ReactMarkdown>["rehypePlugins"];

/**
 * Renders markdown content with syntax highlighting and citation support.
 * @param content The markdown content to render.
 * @param style Optional CSS styles to apply to the rendered content.
 * @param sources List of available sources for citations.
 */
function _MarkdownRenderer({
  content,
  style,
  sources,
  onHighlight,
}: MarkdownRendererProps) {
  const handleSelection = (event: React.MouseEvent) => {
    if (!onHighlight) return;

    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      onHighlight(null);
      return;
    }
    const selectedText = selection.toString().trim();
    const context = selection.getRangeAt(0).startContainer.textContent;
    if (selectedText) {
      onHighlight({
        x: event.clientX,
        y: event.clientY,
        text: selectedText,
        context,
      });
    }
  };

  const cleanContent = useMemo(() => {
    const markdownStart = "<artifact>";
    const markdownEnd = "</artifact>";

    const startIdx = content.indexOf(markdownStart);
    const endIdx = content.indexOf(
      markdownEnd,
      startIdx + markdownStart.length
    );

    if (startIdx !== -1) {
      // if markdown start and end is found return content without markdown
      if (endIdx !== -1) {
        return (
          content.slice(0, startIdx) +
          content.slice(endIdx + markdownEnd.length)
        );
      }

      // if only start is found, return content up to start
      return content.slice(0, startIdx);
    }

    return content;
  }, [content]);

  return (
    <div
      className="markdown-body .light relative"
      style={style ?? {}}
      onMouseUp={(e) => onHighlight && handleSelection(e)}
    >
      <ReactMarkdown
        remarkPlugins={remarkPlugins}
        rehypePlugins={rehypePlugins}
        className="white-space-pre-wrap leading-loose"
        skipHtml={true}
        components={{
          p: (pProps) => (
            <p {...pProps} dir="auto" className="pointer-events-auto" />
          ),
          a: (aProps) => {
            const href = aProps.href || "";
            const isInternal = /^\/#/i.test(href);
            const target = isInternal ? "_self" : aProps.target ?? "_blank";
            return <a {...aProps} target={target} />;
          },
          pre: CodeBlock,
          cite(props) {
            return sources != undefined && sources.length > 0 ? (
              <InlineCitation {...props} availableSources={sources ?? []} />
            ) : undefined;
          },
        }}
      >
        {cleanContent}
      </ReactMarkdown>
    </div>
  );
}

/**
 * {@inheritDoc _MarkdownRenderer}
 * Memoized version of {@link _MarkdownRenderer}.
 */
export const MarkdownRenderer = React.memo(_MarkdownRenderer);
