import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Autocomplete, AutocompleteOption } from "@mui/joy";
import { AutoAwesome } from "@mui/icons-material";

interface LanguagePickerProps {
  selectedLanguage: string;
  languages: string[];
  onSelect: (language: string) => void;
}

export function LanguagePicker({
  selectedLanguage,
  languages,
  onSelect,
  ...otherProps
}: LanguagePickerProps) {
  const { t } = useTranslation();

  const sortedLanguages = useMemo(() => {
    return [...languages].sort((a, b) => {
      if (a === "detect" || b === "detect") return 1;
      return t("languages." + a).localeCompare(t("languages." + b));
    });
  }, [t, languages]);

  return (
    <Autocomplete
      value={selectedLanguage}
      startDecorator={
        selectedLanguage === "detect" && <AutoAwesome color="primary" />
      }
      onChange={(_, newValue) => {
        newValue && onSelect(newValue);
      }}
      options={sortedLanguages}
      getOptionLabel={(option) => t(`languages.${option}`)}
      renderOption={(props, option) => (
        <AutocompleteOption {...props} value={option}>
          {option == "detect" && <AutoAwesome color="primary" />}
          {t(`languages.${option}`)}
        </AutocompleteOption>
      )}
      {...otherProps}
    />
  );
}
