import { ImportExport } from "@mui/icons-material";
import { Box, IconButton } from "@mui/joy";
import { targetToSourceLanguage, sourceToTargetLanguage } from "../../lib/util";
import { LanguagePicker } from "../input/LanguagePicker";
import {
  DEEPL_LANGUAGES_SOURCE,
  DEEPL_LANGUAGES_TARGET,
} from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";
import { type SelectedLanguages } from "../../pages/[organizationId]/tools/translateContent";
import {
  SOURCE_LANG_PICKER_ID,
  TARGET_LANG_PICKER_ID,
} from "../../lib/testIds";

export function LanguagesPicker({
  setSourceText,
  targetText,
  selectedLanguages,
  setSelectedLanguages,
  detectLanguage,
  languagesOverride,
  isGlossaryModal,
}: {
  setSourceText?: (text: string) => void;
  targetText?: string;
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (languages: SelectedLanguages) => void;
  detectLanguage?: boolean;
  languagesOverride?: string[];
  isGlossaryModal?: boolean;
}) {
  const { sourceLanguage, targetLanguage } = selectedLanguages;
  const switchPossible = sourceLanguage !== "detect";
  const REPLACE_LANGUAGE = "de";
  const BACKUP_SOURCE_REPLACE_LANGUAGE = "en";
  const BACKUP_TARGET_REPLACE_LANGUAGE =
    !languagesOverride || languagesOverride?.includes("en-GB") ? "en-GB" : "en";

  const handleSourceChange = (language) => {
    let newTarget: string | undefined;
    if (targetLanguage.includes(language)) {
      newTarget = targetLanguage.includes(REPLACE_LANGUAGE)
        ? BACKUP_TARGET_REPLACE_LANGUAGE
        : REPLACE_LANGUAGE;
    }

    setSelectedLanguages({
      targetLanguage: newTarget || targetLanguage,
      sourceLanguage: language,
    });
  };
  const handleTargetChange = (language: string) => {
    let newSource: string | undefined;
    if (language.includes(sourceLanguage)) {
      newSource = sourceLanguage.includes(REPLACE_LANGUAGE)
        ? BACKUP_SOURCE_REPLACE_LANGUAGE
        : REPLACE_LANGUAGE;
    }

    setSelectedLanguages({
      sourceLanguage: newSource || sourceLanguage,
      targetLanguage: language,
    });
  };

  return (
    <Box sx={{ justifyContent: "space-between" }} display="flex">
      <Box sx={{ flex: 1 }}>
        <LanguagePicker
          selectedLanguage={sourceLanguage}
          data-testid={SOURCE_LANG_PICKER_ID}
          languages={
            languagesOverride ??
            (detectLanguage
              ? ["detect", ...DEEPL_LANGUAGES_SOURCE]
              : [...DEEPL_LANGUAGES_SOURCE])
          }
          onSelect={(language) => {
            handleSourceChange(language);
          }}
        />
      </Box>
      <IconButton
        sx={{ mx: 4, opacity: switchPossible ? 1 : 0.5 }}
        disabled={!switchPossible}
        onClick={() => {
          targetText && setSourceText?.(targetText);
          setSelectedLanguages({
            sourceLanguage: isGlossaryModal
              ? targetLanguage
              : targetToSourceLanguage(targetLanguage),
            targetLanguage: isGlossaryModal
              ? sourceLanguage
              : sourceToTargetLanguage(sourceLanguage),
          });
        }}
      >
        <ImportExport sx={{ transform: "rotate(90deg)" }} />
      </IconButton>

      <Box sx={{ flex: 1 }}>
        <LanguagePicker
          selectedLanguage={targetLanguage}
          languages={languagesOverride ?? [...DEEPL_LANGUAGES_TARGET]}
          data-testid={TARGET_LANG_PICKER_ID}
          onSelect={(language) => {
            handleTargetChange(language);
          }}
        />
      </Box>
    </Box>
  );
}
