type MimeMap = Record<string, string[]>;

export const extensionsByMimeType: MimeMap = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.docx'],
  'application/msexcel': ['.xlsx'],
  'application/mspowerpoint': ['.pptx'],
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'text/vtt': ['.vtt'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/bmp': ['.bmp'],
  'image/tiff': ['.tiff'],
  'image/heif': ['.heif'],
};

export const codeMimeTypes: MimeMap = {
  'text/x-python': ['.py'],
  'text/javascript': ['.js'],
  'text/typescript': ['.ts'],
  'text/x-java': ['.java'],
  'text/x-c': ['.c'],
  'text/x-c++': ['.cpp'],
  'text/x-h': ['.h'],
  'text/x-h++': ['.hpp'],
  'text/plain': ['.txt'],
  'text/html': ['.html', '.htm'],
  'text/css': ['.css'],
  'text/x-sql': ['.sql'],
  'text/x-shellscript': ['.sh', '.bash'],
  'text/x-csharp': ['.cs'],
  'text/x-php': ['.php'],
};

export const documentMimeType: MimeMap = {
  'application/msword': ['.docx', '.doc'],
  'application/mspowerpoint': ['.pptx', '.ppt'],
  'application/msexcel': ['.xlsx', '.xls'],
  'application/pdf': ['.pdf'],
  'text/html': ['.htm', '.html'],
  'text/plain': ['.txt'],
  'application/x-subrip': ['.srt'],
};

export const audioMimeType: MimeMap = {
  'audio/aac': ['.aac'],
  'audio/ac3': ['.ac3'],
  'audio/eac3': ['.eac3'],
  'audio/flac': ['.flac'],
  'audio/mp4': ['.m4a'],
  'audio/mpeg': ['.mp2', '.mp3'],
  'application/ogg': ['.ogg'],
  'audio/opus': ['.opus'],
  'audio/wav': ['.wav'],
};

export function getMimeListFromMap(map: MimeMap) {
  return Array.from(
    new Set([...Object.keys(map), ...Object.values(map).flat()]),
  );
}

export function getExtensionsFromMimeMap(map: MimeMap) {
  return Object.values(map).flat();
}

function getMimeListFromMaps(maps: MimeMap[]) {
  return maps.map(getMimeListFromMap).join(', ');
}

export const allowedMimeTypesForAdiDocuments = getMimeListFromMaps([
  extensionsByMimeType,
  codeMimeTypes,
]);
