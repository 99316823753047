import { Typography } from "@mui/joy";
import { Stack } from "@mui/system";
import { OrganizationButton } from "./OrganizationButton";
import { type OrganizationSelectorEntry } from "../../../../../backend/src/api/organization/organizationTypes";
import { FAVORITE_ORGANIZATIONS_SECTION_ID } from "../../../lib/testIds";
import { ORGANIZATION_SELECTOR_TEST_NAME } from "../../../lib/constants/organizationSelector";

export function OrganizationGroup({
  organizations,
  title,
  isFavorite,
  isHighlighted,
}: {
  organizations: OrganizationSelectorEntry[];
  title: string;
  isFavorite: boolean;
  isHighlighted: boolean;
}) {
  return (
    organizations.length > 0 && (
      <div
        data-testid={
          title === "Favorite" ? FAVORITE_ORGANIZATIONS_SECTION_ID : undefined
        }
      >
        <Typography level="title-md">{title}</Typography>
        <Stack gap={1.5}>
          {organizations.map((org) => (
            <OrganizationButton
              key={org.id}
              organization={org}
              isFavorite={isFavorite}
              isHighlighted={isHighlighted}
              isTestUnit={org.name === ORGANIZATION_SELECTOR_TEST_NAME}
            />
          ))}
        </Stack>
      </div>
    )
  );
}
