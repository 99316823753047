import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useCreateWorkshop } from "../../lib/api/learning.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { CourseSelector } from "./CourseSelector";

export function NewWorkshopButton() {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button startDecorator={<AddIcon />} onClick={() => setModalOpen(true)}>
        {t("newWorkshop")}
      </Button>
      <NewWorkshopModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
}

function generateCode() {
  let code = Math.floor(Math.random() * 1000000).toString();
  while (code.length < 6) {
    code = "0" + code;
  }
  return code;
}

function NewWorkshopModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();

  const { data: user } = trpc.user.me.useQuery();

  const validationSchema = z.object({
    title: z.string({
      required_error: `${t("title")} ${t("errors.cantBeEmpty")}`,
    }),
    loginCode: z
      .string({
        required_error: `${t("accessCode")} ${t("errors.cantBeEmpty")}`,
      })
      .regex(/^\d{6}$/, t("sixDigits")),
    includedCourses: z.array(z.number()),
    organizationId: z.string(),
  });

  type FormValues = z.infer<typeof validationSchema>;

  const createWorkshop = useCreateWorkshop();

  async function handleSubmit(
    { title, loginCode, includedCourses, organizationId }: FormValues,
    { resetForm }
  ) {
    await createWorkshop({
      name: title,
      loginCode,
      courses: (includedCourses ?? []).map((id) => String(id)),
      status: "PENDING",
      organizationId,
    }).then(() => {
      handleClose();
      resetForm();
    });
  }

  if (!user) {
    return;
  }
  const viableOrganizations = user.organizations.filter(
    (org) => org.id !== "academy"
  );
  const initialValues: FormValues = {
    title: "",
    loginCode: generateCode(),
    includedCourses: [],
    organizationId: viableOrganizations[0].id,
  };

  return (
    <Modal
      open={open}
      onClose={(_e, reason: string) => {
        if (reason === "closeClick") handleClose();
      }}
    >
      <ModalDialog minWidth="sm">
        <ModalClose />
        <Typography level="title-lg">{t("createWorkshop")}</Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({
            errors,
            touched,
            resetForm,
            setFieldValue,
            values,
            getFieldProps,
            isValid,
            isSubmitting,
          }) => (
            <Form className="flex flex-col gap-4 overflow-hidden">
              <FormControl
                error={Boolean(errors.title) && touched.title}
                required
              >
                <FormLabel>{t("title")}</FormLabel>
                <Input placeholder="Titel" {...getFieldProps("title")} />
                <FormHelperText>
                  {errors.title && touched.title
                    ? errors.title
                    : t("titleForWorkshop")}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.loginCode) && touched.loginCode}
              >
                <FormLabel>{t("accessCode")}</FormLabel>
                <Input
                  id="loginCode"
                  placeholder="Zugangscode"
                  inputMode="numeric"
                  {...getFieldProps("loginCode")}
                  error={Boolean(errors.loginCode) && touched.loginCode}
                />
                <FormHelperText>
                  {errors.loginCode && touched.loginCode
                    ? errors.loginCode
                    : t("accessCodeForWorkshop")}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.organizationId) && touched.organizationId}
              >
                <FormLabel>{t("organization")}</FormLabel>
                <Select
                  disabled={viableOrganizations.length === 1}
                  value={values.organizationId}
                  onChange={(organizationId) =>
                    setFieldValue("organizationId", organizationId)
                  }
                  id="organizationId"
                >
                  {viableOrganizations.map((organization) => (
                    <Option value={organization.id} key={organization.id}>
                      {organization.name}
                    </Option>
                  ))}
                </Select>
                <FormHelperText>
                  {t("owningOrganizationOfWorkshop")}
                </FormHelperText>
              </FormControl>

              <div
                role="group"
                className="flex min-h-0 flex-col gap-1 overflow-hidden"
              >
                <FormLabel id="course-group">{t("courses")}</FormLabel>
                <CourseSelector
                  selectedCourseIds={values.includedCourses.map(String)}
                  onToggle={(courseIds, checked) => {
                    const currentCourses = new Set(values.includedCourses);
                    courseIds.forEach((id) => {
                      if (checked) {
                        currentCourses.add(id);
                      } else {
                        currentCourses.delete(id);
                      }
                    });
                    void setFieldValue(
                      "includedCourses",
                      Array.from(currentCourses)
                    );
                  }}
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  variant="plain"
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {t("create")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  );
}
