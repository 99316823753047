import {
  Card,
  CircularProgress,
  FormControl,
  FormLabel,
  Sheet,
  Switch,
  Typography,
} from "@mui/joy";
import { captureException } from "@sentry/react";
import type { TRPCError } from "@trpc/server";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { MeinGPTLogo } from "../../../components/branding/BrandedLogo";
import { Citations } from "../../../components/chat/Citations";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer";
import { SearchBar } from "../../../components/chat/researchAssistantChatInput";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useTranslation } from "../../../lib/i18n";

export default function WebSearchUI() {
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");

  const { data: productConfig } = trpc.productConfig.get.useQuery();

  const search = trpc.tools.webSearch.queryLLM.useMutation();
  const [response, setResponse] = useState<string>("");
  const [citations, setCitations] = useState<
    {
      link: string;
      title: string;
    }[]
  >([]);

  const [betaEnabled, setBetaEnabled] = useState(false);
  const allowBeta = productConfig?.webSearchBeta ?? false;
  useEffect(() => setBetaEnabled(allowBeta), [allowBeta]);

  const [generating, setGenerating] = useState(false);

  const cancelCurrent = useRef<() => void>(() => {});

  const onCreate = useCallback(
    async (searchQuery: string) => {
      if (!searchQuery) return;

      cancelCurrent.current();
      let cancelled = false;
      cancelCurrent.current = () => {
        cancelled = true;
      };

      setResponse("");
      setCitations([]);
      setGenerating(true);

      try {
        const stream = await search.mutateAsync({
          query: searchQuery,
          beta: !!betaEnabled,
        });

        for await (const chunk of stream) {
          if (cancelled) return;
          setResponse(chunk.response);
          setCitations(
            chunk.citations.map((cit) =>
              typeof cit == "string" ? { link: cit, title: cit } : cit
            ) ?? []
          );
        }
        if (cancelled) return;
        setGenerating(false);
      } catch (e) {
        console.error(e);
        captureException(e);
        setGenerating(false);
        const message = (e as TRPCError).message;
        toast.error(t("errorDisplay.title") + ": " + message);
        setResponse(t("errorDisplay.title"));
      }
    },
    [search, betaEnabled, t]
  );

  const hasAnswer = search.isPending || search.isSuccess;

  const betaSwitch = allowBeta ? (
    <FormControl orientation="horizontal">
      <FormLabel>{t("researchAssistantBeta")}</FormLabel>
      <Switch
        checked={betaEnabled}
        onChange={(e) => setBetaEnabled(e.target.checked)}
      />
    </FormControl>
  ) : null;

  return (
    <Sheet variant="soft" className="min-h-screen">
      {hasAnswer ? (
        <div className="flex max-w-7xl flex-col justify-center gap-6 p-16">
          <div className="flex flex-row items-center gap-6">
            <SearchBar query={query} setQuery={setQuery} onCreate={onCreate} />{" "}
            {betaSwitch}
          </div>
          <div className="max-w-7xl">
            <Typography
              level="body-md"
              mb={2}
              className="w-full overflow-x-hidden break-words"
            >
              {t("resultsFor")} &quot;{search.variables?.query}&quot;
            </Typography>
            <Card>
              <MarkdownRenderer content={response} />
              {generating && <CircularProgress />}
              <Citations citations={citations} />
            </Card>
          </div>
        </div>
      ) : (
        <div className="flex h-screen w-full items-center justify-center p-12">
          <div className="flex max-w-[650px] grow flex-col items-stretch gap-6 ">
            <div className="flex w-full flex-col items-center">
              <MeinGPTLogo width={300} />
            </div>
            <SearchBar query={query} setQuery={setQuery} onCreate={onCreate} />{" "}
            {betaSwitch}
            <Typography level="body-sm" textAlign="center" color="neutral">
              {t("researchAssistantDisclaimer")}
            </Typography>
          </div>
        </div>
      )}
    </Sheet>
  );
}
