import { useCallback, useState } from "react";
import { useOrganizationApi } from "./hooks/useApi";

export function useDownloadAsDoc({
  markdown,
  filename,
}: {
  markdown: string;
  filename: string;
}): {
  downloadDocument: (format: "docx" | "pdf") => Promise<void>;
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Safari has issues with certain characters in filenames
  const sanitizedFilename = filename.replace(/[^a-zA-Z0-9-_]/g, "-");

  const orgApi = useOrganizationApi();

  const downloadDocument = useCallback(
    async (format: "docx" | "pdf") => {
      setIsLoading(true);
      const res = await orgApi.post(
        `documents/download`,
        {
          markdown,
          filename: sanitizedFilename,
          format,
        },
        {
          responseType: "blob",
        }
      );

      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.download = `${sanitizedFilename}.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
    },
    [markdown, sanitizedFilename, orgApi, setIsLoading]
  );

  return { downloadDocument, isLoading };
}
