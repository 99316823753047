import { WhatsApp } from "@mui/icons-material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import KeyboardVoiceRoundedIcon from "@mui/icons-material/KeyboardVoiceRounded";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import type { FormikHelpers } from "formik";
import { Form, Formik } from "formik";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import QrCode from "../../../assets/whatsapp_qr.png";
import { FormikTextField } from "../../../components/forms/FormikTextField";
import ToolPage from "../../../components/sidebar/ToolPage";

import { trpc } from "../../../lib/api/trpc/trpc";

interface FormValues {
  phoneNumber: string;
}

export default function PersonalAssistant() {
  const { t } = useTranslation();
  const utils = trpc.useUtils();
  const { data: status, isLoading } =
    trpc.tools.personalAssistant.getStatus.useQuery();

  const registerMutation = trpc.tools.personalAssistant.register.useMutation({
    onSuccess: async () => {
      toast.success(
        status?.data.isRegistered
          ? t("personalAssistant.toasts.numberUpdated")
          : t("personalAssistant.toasts.registered")
      );
      await utils.tools.personalAssistant.getStatus.invalidate();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const validationSchema = z.object({
    phoneNumber: z
      .string({
        required_error: t("personalAssistant.errors.required"),
      })
      .min(1, t("personalAssistant.errors.required"))
      .refine((value) => {
        try {
          return isValidPhoneNumber(value);
        } catch (error) {
          return false;
        }
      }, t("personalAssistant.errors.invalidPhone")),
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      await registerMutation.mutateAsync({ phoneNumber: values.phoneNumber });
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <ToolPage
        title={t("personalAssistant.title")}
        subtitle={t("personalAssistant.subtitle")}
      >
        <Card className="w-full max-w-6xl">
          <Typography>{t("personalAssistant.status.loading")}</Typography>
        </Card>
      </ToolPage>
    );
  }

  const isRegistered = status?.data.isRegistered;
  const initialValues: FormValues = {
    phoneNumber: status?.data.phoneNumber || "",
  };

  return (
    <ToolPage
      title={t("personalAssistant.title")}
      subtitle={t("personalAssistant.subtitle")}
    >
      <div className="flex w-full max-w-6xl flex-col gap-6">
        {/* Capabilities Card */}
        <Card>
          <div className="space-y-8">
            <div>
              <Typography level="title-lg" sx={{ mb: 1 }}>
                {t("personalAssistant.capabilities.title")}
              </Typography>
              <Typography level="body-md">
                {t("personalAssistant.capabilities.description", {
                  phoneNumber: "+49 89 62825145",
                })}
              </Typography>
            </div>

            <div className="grid grid-cols-1 gap-x-16 gap-y-6 md:grid-cols-2">
              <div>
                <Typography level="title-lg" sx={{ mb: 2 }}>
                  {t("personalAssistant.capabilities.whatAssistantCanDo")}
                </Typography>
                <List>
                  <ListItem>
                    <ListItemDecorator>
                      <SearchRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.features.research")}
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>
                      <EmailRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.features.email")}
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>
                      <AccessTimeRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.features.tasks")}
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>
                      <MemoryRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.features.memory")}
                  </ListItem>
                </List>
              </div>

              <div>
                <Typography level="title-lg" sx={{ mb: 2 }}>
                  {t("personalAssistant.capabilities.understandsFormats")}
                </Typography>
                <List>
                  <ListItem>
                    <ListItemDecorator>
                      <ChatRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.formats.text")}
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>
                      <KeyboardVoiceRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.formats.voice")}
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator>
                      <ImageRoundedIcon />
                    </ListItemDecorator>
                    {t("personalAssistant.capabilities.formats.images")}
                  </ListItem>
                </List>
              </div>
            </div>
          </div>
        </Card>

        {/* Registration and Status Card */}
        <Card>
          <div className="grid min-h-[400px] grid-cols-1 md:grid-cols-2">
            {/* Registration Form */}
            <div className="flex h-full flex-col p-8 md:pr-12">
              <Formik
                initialValues={initialValues}
                validationSchema={toFormikValidationSchema(validationSchema)}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, touched, errors, isSubmitting, dirty }) => (
                  <Form className="flex h-full flex-col">
                    <div className="space-y-8">
                      <div className="space-y-1">
                        <Typography level="title-lg">
                          {isRegistered
                            ? t("personalAssistant.registration.updateTitle")
                            : t("personalAssistant.registration.title")}
                        </Typography>
                        <Typography level="body-sm">
                          {isRegistered
                            ? t(
                                "personalAssistant.registration.updateDescription"
                              )
                            : t("personalAssistant.registration.description")}
                        </Typography>
                      </div>

                      <FormikTextField
                        name="phoneNumber"
                        type="tel"
                        label={t("personalAssistant.registration.phoneNumber")}
                        helperText={t(
                          "personalAssistant.registration.phoneNumberHint"
                        )}
                        placeholder="+1234567890"
                        required
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div className="mt-auto pt-8">
                      <Button
                        type="submit"
                        color="primary"
                        loading={isSubmitting}
                        disabled={
                          isSubmitting ||
                          !values.phoneNumber ||
                          (isRegistered && !dirty) ||
                          (touched.phoneNumber && !!errors.phoneNumber)
                        }
                        fullWidth
                      >
                        {isRegistered
                          ? t("personalAssistant.registration.update")
                          : t("personalAssistant.registration.submit")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Status and QR Code */}
            <div className="border-divider flex items-center justify-center border-t p-8 md:border-l md:border-t-0 md:pl-12">
              {isRegistered ? (
                <div className="flex flex-col items-center gap-6">
                  <Typography level="title-lg" color="success">
                    {t("personalAssistant.status.active")}
                  </Typography>
                  <div className="flex flex-col items-center gap-4">
                    <div className="flex h-48 w-48 items-center justify-center">
                      <img
                        src={QrCode}
                        alt="WhatsApp QR Code"
                        className="h-full w-full object-contain"
                      />
                    </div>
                    <div className="text-center">
                      <Typography level="title-md">+49 89 62825145</Typography>
                      <Typography level="body-md" sx={{ mb: 1 }}>
                        {t("personalAssistant.status.scanQr")}
                      </Typography>
                    </div>
                    <Button
                      startDecorator={<WhatsApp />}
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/498962825145"
                      variant="soft"
                    >
                      {t("personalAssistant.status.openInWhatsApp")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-4 p-8 text-center">
                  <Typography level="title-lg" color="neutral">
                    {t("personalAssistant.status.notRegistered")}
                  </Typography>
                  <Typography level="body-md">
                    {t("personalAssistant.status.notRegisteredDesc")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </ToolPage>
  );
}
