import z from 'zod';
import { DEFAULT_ENABLED_MODELS } from '../../../ai/llmMeta';

export const DEFAULT_PRODUCT_CONFIG = {
  create: {
    enabledModels: DEFAULT_ENABLED_MODELS,
    translateText: true,
    translateDocument: true,
  },
};

export const ProductConfig = z.object({
  // Feature Flags
  documentIntelligence: z.boolean(),
  creditSystem: z.boolean(),
  entraId: z.boolean(),
  helpdesk: z.boolean(),
  meetingSummarizer: z.boolean(),
  translateText: z.boolean(),
  translateDocument: z.boolean(),
  anonymizeMetrics: z.boolean(),
  enableRag: z.boolean(),
  webSearchBeta: z.boolean(),
  meetingTranscription: z.boolean(),
  workflowApi: z.boolean(),
  completionsApi: z.boolean(),
  imageGeneration: z.boolean(),
  personalAssistant: z.boolean(),

  enabledModels: z.array(z.string()),

  guidelines: z.string().nullable(),
  guidelinesLastUpdate: z.date().nullable(),
});

export type ProductConfig = z.infer<typeof ProductConfig>;
