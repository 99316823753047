import { Button, Card, Input, Stack, Typography } from "@mui/joy";
import { useNavigate } from "../../../router";
import { useTranslation } from "../../../lib/i18n";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useEffect, useMemo, useState } from "react";
import CreateOrganizationModal from "../CreateOrganizationModal";
import { useTrialStore } from "../../../lib/context/trialModalStore";
import { OrganizationGroup } from "./OrganizationGroup";

export function OrganizationSelector() {
  const { t } = useTranslation();
  const { reset } = useTrialStore();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [newOrgModal, setNewOrgModal] = useState<boolean>(false);

  const { data: categorizedOrganizations } =
    trpc.organization.getOrganizationsGroupedByPhase.useQuery();

  useEffect(() => reset(), [reset]);

  const filteredOrganizations = useMemo(
    () =>
      categorizedOrganizations?.map((category) => {
        if (!search) return category;
        return {
          ...category,
          organizations: category.organizations.filter(
            (org) =>
              org.name.toLowerCase().includes(search.toLowerCase()) ||
              org.tags.some((tag) =>
                tag.toLowerCase().includes(search.toLowerCase())
              )
          ),
        };
      }),
    [categorizedOrganizations, search]
  );

  if (!filteredOrganizations) return null;

  const allFilteredOrganizations = filteredOrganizations.flatMap(
    (organization) => organization.organizations
  );

  return (
    <>
      <Stack gap={2}>
        <Typography level="title-lg" textAlign="center" my={2}>
          {t("selectOrganization")}
        </Typography>
        <Card>
          <Stack gap={3} alignItems="stretch">
            <form
              onSubmit={(e) => {
                if (allFilteredOrganizations.length !== 1) {
                  e.preventDefault();
                  return;
                }
                navigate("/:organizationId", {
                  params: {
                    organizationId: allFilteredOrganizations[0].id,
                  },
                  replace: true,
                });
              }}
            >
              <div className="flex gap-3">
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={t("search")}
                  autoFocus
                  fullWidth
                />
                <Button onClick={() => setNewOrgModal(true)}>
                  {t("create")}
                </Button>
              </div>
            </form>
            {filteredOrganizations?.map((category) => (
              <OrganizationGroup
                key={category.title}
                organizations={category.organizations}
                title={category.title}
                isFavorite={category.title === "Favorite"}
                isHighlighted={allFilteredOrganizations.length === 1}
              />
            ))}
          </Stack>
        </Card>
      </Stack>
      <CreateOrganizationModal
        open={newOrgModal}
        onClose={() => setNewOrgModal(false)}
      />
    </>
  );
}
