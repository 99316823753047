import { Alert, Link } from "@mui/joy";
import React, { useCallback } from "react";
import "../../styles/markdown.scss";

interface Block {
  type?: string;
  text?: string;
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  children?: Block[];
  url?: string;
  value?: {
    url: string;
    alt: string | null;
    [key: string]: unknown;
  };
}

const props = {
  h1: "text-4xl font-bold",
  h2: "text-3xl font-bold",
  h3: "text-2xl font-bold",
  h4: "text-xl font-bold",
  h5: "text-lg font-bold",
  h6: "text-base font-bold",
  ul: "list-disc !mb-0",
  ol: "list-decimal !mb-0",
  indent: "bg-gray-100 p-2 rounded-md whitespace-pre-wrap",
  p: "!mb-0",
};

export function SlateRenderer({ content }: { content: Block[] }) {
  const renderElement = useCallback(
    (currentBlock: Block, defaultElement: string = "span") => {
      if (currentBlock.type === "upload") {
        return (
          <>
            <img
              src={"https://cms.meingpt.com" + currentBlock.value?.url}
              alt={currentBlock.value?.alt ?? ""}
              height="200px"
              className="h-52 rounded-md"
            />
            <br />
          </>
        );
      }

      if (currentBlock.type === "link") {
        return (
          <Link href={currentBlock.url} target="_blank" rel="noreferrer">
            {currentBlock.children?.map((block) => renderElement(block)) ??
              currentBlock.url}
          </Link>
        );
      } else if (currentBlock.type == "blockquote") {
        return (
          <Alert variant="outlined" color="neutral">
            {currentBlock.children?.map((block) => renderElement(block))}
          </Alert>
        );
      }

      if (currentBlock.children) {
        return React.createElement(
          currentBlock.type ?? defaultElement,
          {
            className: props[currentBlock.type ?? defaultElement ?? ""],
          },
          currentBlock.children.map((block, i) => (
            <React.Fragment key={i}>
              {renderElement(
                block,
                currentBlock.type == "indent" ? "p" : defaultElement
              )}
            </React.Fragment>
          ))
        );
      } else {
        if (currentBlock.text === "") return <br />;
        let formattedText = currentBlock.bold ? (
          <b>{currentBlock.text}</b>
        ) : (
          currentBlock.text
        );
        formattedText = currentBlock.italic ? (
          <i>{formattedText}</i>
        ) : (
          formattedText
        );
        formattedText = currentBlock.underline ? (
          <u>{formattedText}</u>
        ) : (
          formattedText
        );
        formattedText = currentBlock.strikethrough ? (
          <s>{formattedText}</s>
        ) : (
          formattedText
        );
        return formattedText;
      }
    },
    []
  );

  return content ? (
    <div className="markdown-body">
      {content.map((block, i) =>
        block.type ? (
          <React.Fragment key={i}>{renderElement(block)}</React.Fragment>
        ) : (
          <p key={i}> {renderElement(block)}</p>
        )
      )}
    </div>
  ) : null;
}
