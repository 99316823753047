import { Autocomplete, Button, Checkbox, Chip, Input, List } from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import type { Tag } from "apiTypes/dist/payload-types";
import { useCallback, useState } from "react";
import { trpc } from "../../lib/api/trpc/trpc";
import { Search, Sell } from "@mui/icons-material";
import { useTranslation } from "../../lib/i18n.ts";

interface CourseSelectorProps {
  selectedCourseIds?: string[];
  onToggle: (courseIds: number[], checked: boolean) => void;
  disabled?: boolean;
}

export function CourseSelector({
  selectedCourseIds = [],
  onToggle,
  disabled = false,
}: CourseSelectorProps) {
  const { t } = useTranslation(undefined, { keyPrefix: "academySettings" });

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: tags } = trpc.elearning.tag.getAll.useQuery();

  const { data: courses } = trpc.elearning.course.getAll.useQuery(
    {
      tags: selectedTags.map((tag) => tag.id),
    },
    { placeholderData: (prev) => prev }
  );

  const filteredCourses = (courses ?? [])
    .filter((course) => {
      if (searchQuery === "") return true;
      return course.title.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  const toggleAll = useCallback(() => {
    if (!filteredCourses) return;

    // Determine if we should select or deselect all
    // If any visible course is not selected, we'll select all
    // Otherwise, we'll deselect all
    const hasUnselectedCourses = filteredCourses.some(
      (course) => !selectedCourseIds.includes(course.id.toString())
    );

    const courseIds = filteredCourses.map((course) => course.id);
    onToggle(courseIds, hasUnselectedCourses);
  }, [filteredCourses, onToggle, selectedCourseIds]);

  const handleSingleToggle = useCallback(
    (courseId: number, checked: boolean) => {
      onToggle([courseId], checked);
    },
    [onToggle]
  );

  return (
    <div className="flex max-h-[65dvh] min-h-0 flex-col gap-4">
      <Input
        startDecorator={<Search />}
        placeholder={t("searchCourses")}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value ?? "")}
        data-testid="course-search-input"
      />
      <Autocomplete
        startDecorator={<Sell />}
        multiple
        placeholder={t("searchTags")}
        loading={tags === undefined}
        options={(tags ?? []).filter((tag) => tag.name)}
        getOptionLabel={(tag) => tag.name}
        getOptionKey={(tag) => tag.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedTags}
        onChange={(_e, value) => setSelectedTags(value)}
        data-testid="course-tags-autocomplete"
      />
      <List className="min-h-0 overflow-auto">
        {(filteredCourses ?? [])
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((course) => (
            <ListItem key={course.id}>
              <Checkbox
                label={course.title}
                checked={selectedCourseIds.includes(course.id.toString())}
                onChange={(e) =>
                  handleSingleToggle(course.id, e.target.checked)
                }
                disabled={disabled}
              />
              <div className="flex gap-2">
                {course.Tags?.map((tag) => {
                  if (typeof tag === "number") return null;
                  return (
                    <Chip
                      key={tag.id}
                      onClick={() => {
                        setSelectedTags((prev) => {
                          if (prev.some((prevTag) => prevTag.id === tag.id)) {
                            return prev;
                          }
                          return [...prev, tag];
                        });
                      }}
                      title={tag.name}
                      color="primary"
                      size="md"
                      variant="soft"
                    >
                      {tag.name}
                    </Chip>
                  );
                })}
              </div>
            </ListItem>
          ))}
      </List>
      <Button
        variant="outlined"
        onClick={toggleAll}
        disabled={disabled || !filteredCourses?.length}
        sx={{ alignSelf: "start" }}
      >
        {filteredCourses?.some(
          (course) => !selectedCourseIds.includes(course.id.toString())
        )
          ? t("selectAllCourses")
          : t("deselectAllCourses")}
      </Button>
    </div>
  );
}
