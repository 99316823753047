// Sidebar
export const SIDEBAR_TOGGLE_BUTTON_ID = "sidebar-toggle-button";
export const COLLAPSED_SIDEBAR_ID = "collapsed-sidebar";
export const EXPANDED_SIDEBAR_ID = "expanded-sidebar";

// Translate Content
export const SOURCE_LANG_PICKER_ID = "source-lang-picker";
export const TARGET_LANG_PICKER_ID = "target-lang-picker";
export const TRANSLATE_CONTENT_SIDEBAR_BUTTON_ID =
  "translate-content-sidebar-button";
export const SIDEBAR_SETTINGS_BUTTON_ID = "sidebar-settings-button";

// Chat
export const SUBMIT_MESSAGE_BUTTON_ID = "submit-message-button";
export const CHAT_INPUT_ID = "chat-input";

// Artifact
export const ARTIFACT_CANVAS_ID = "artifact-canvas";
export const ARTIFACT_PREVIEW_ID = "artifact-preview";

// Sidebar User Section
export const SIDEBAR_USER_SECTION_ID = "sidebar-user-section";

// Organization Selector
export const ORGANIZATION_NAVIGATION_BUTTON_ID =
  "organization-navigation-button";
export const ORGANIZATION_TAG_BUTTON_ID = "organization-tag-button";
export const ORGANIZATION_TAGS_ID = "organization-tags";
export const ORGANIZATION_FAVORITE_BUTTON_ID = "organization-favorite-button";
export const FAVORITE_ORGANIZATIONS_SECTION_ID =
  "favorite-organizations-section";

// Meetings
export const TRANSCRIBE_BUTTON = "transcribe-button";
export const TRANSCRIBE_ERROR = "transcribe-error-message";

// Settings
export const ACADEMY_TOGGLE_BUTTON = "academy-toggle-button";
export const ACADEMY_SAVE_BUTTON = "academy-save-button";
