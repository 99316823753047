import { Button } from "@mui/joy";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../lib/api/trpc/trpc";
import { CourseSelector } from "../elearning/CourseSelector";
import { SettingsPage } from "./SettingsPage";
import { ACADEMY_SAVE_BUTTON, ACADEMY_TOGGLE_BUTTON } from "../../lib/testIds";

export function EnableAcademy() {
  // Queries
  const { data: initialEnabledCourses, isPending: coursesLoading } =
    trpc.organization.academy.getAvailableCourses.useQuery();
  const { data: isActive } = trpc.organization.academy.isEnabled.useQuery();

  // Mutations
  const enableAcademy = trpc.organization.academy.enable.useMutation();
  const disableAcademy = trpc.organization.academy.disable.useMutation();
  const setAvailableCourses =
    trpc.organization.academy.setAvailableCourses.useMutation();

  const utils = trpc.useUtils();

  // Track pending changes in local state
  const [enabledCourses, setEnabledCourses] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (initialEnabledCourses) {
      setEnabledCourses(initialEnabledCourses);
    }
  }, [initialEnabledCourses]);

  const toggleAcademyAvailability = async (enabled: boolean) => {
    try {
      if (enabled) {
        await enableAcademy.mutateAsync({
          courses: enabledCourses,
        });
      } else {
        await disableAcademy.mutateAsync();
      }
      void utils.organization.invalidate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleToggle = useCallback((courseIds: number[], checked: boolean) => {
    setEnabledCourses((current) => {
      const newCourses = new Set(current);
      courseIds.forEach((id) => {
        const courseId = id.toString();
        if (checked) {
          newCourses.add(courseId);
        } else {
          newCourses.delete(courseId);
        }
      });
      setHasChanges(true);
      return Array.from(newCourses);
    });
  }, []);

  const saveChanges = useCallback(async () => {
    try {
      await setAvailableCourses.mutateAsync(enabledCourses);
      void utils.organization.invalidate();

      setHasChanges(false);
      toast.success(t("academySettings.saved"));
    } catch (e) {
      console.error(e);
      toast.error(t("errors.network"));
    }
  }, [enabledCourses, setAvailableCourses, utils.organization]);

  return (
    <SettingsPage
      title={t("academySettings.title")}
      subtitle={t("academySettings.subtitle")}
      actions={
        <Button
          data-testid={ACADEMY_TOGGLE_BUTTON}
          color={isActive ? "danger" : "primary"}
          variant={isActive ? "outlined" : "solid"}
          onClick={async () => {
            await toggleAcademyAvailability(!isActive);
          }}
        >
          {isActive ? t("disable") : t("enable")}
        </Button>
      }
    >
      <CourseSelector
        selectedCourseIds={enabledCourses}
        onToggle={handleToggle}
        disabled={!isActive || coursesLoading || setAvailableCourses.isPending}
      />
      {hasChanges && (
        <Button
          color="primary"
          variant="solid"
          onClick={saveChanges}
          data-testid={ACADEMY_SAVE_BUTTON}
        >
          {t("save")}
        </Button>
      )}
    </SettingsPage>
  );
}
