import {
  ArrowForward,
  Tag,
  Bookmark,
  BookmarkBorder,
} from "@mui/icons-material";
import {
  ButtonGroup,
  IconButton,
  Button,
  Typography,
  Chip,
  ChipDelete,
} from "@mui/joy";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "../../../router";
import { useMemo } from "react";
import { getPhaseProgress } from "../../sidebar/phase/utils";
import { PhaseProgress } from "../../sidebar/phase/PhaseProgress";
import { useTranslation } from "react-i18next";
import { trpc } from "../../../lib/api/trpc/trpc";
import { type OrganizationSelectorEntry } from "../../../../../backend/src/api/organization/organizationTypes";
import {
  ORGANIZATION_FAVORITE_BUTTON_ID,
  ORGANIZATION_NAVIGATION_BUTTON_ID,
  ORGANIZATION_TAG_BUTTON_ID,
  ORGANIZATION_TAGS_ID,
} from "../../../lib/testIds";

export function OrganizationButton({
  organization,
  isFavorite,
  isHighlighted,
  isTestUnit = false,
}: {
  organization: OrganizationSelectorEntry;
  isFavorite: boolean;
  isHighlighted: boolean;
  isTestUnit: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: setFavoriteOrganization } =
    trpc.user.toggleOrganizationFavoriteStatus.useMutation();
  const { mutateAsync: setTags } = trpc.organization.setTags.useMutation();
  const utils = trpc.useUtils();

  const toggleFavorite = async () => {
    await setFavoriteOrganization({
      organizationId: organization.id,
      isFavorite: !isFavorite,
    });
    void utils.organization.getOrganizationsGroupedByPhase.invalidate();
  };

  const { start, end } = organization;
  const hasPhase = start && end;
  const { range, remaining } = useMemo(() => {
    return hasPhase ? getPhaseProgress(start, end) : { range: 0, remaining: 0 };
  }, [hasPhase, start, end]);

  const getVisibilityClass = (isVisible: boolean) =>
    twMerge("group-hover:opacity-100 opacity-0", isVisible && "opacity-1");

  const addTag = async () => {
    const tagName = prompt("Enter a tag name");
    if (tagName) {
      await setTags({
        organizationId: organization.id,
        tags: [...organization.tags, tagName],
      });
      void utils.organization.invalidate();
    }
  };

  const onDelete = async (tag: string) => {
    await setTags({
      organizationId: organization.id,
      tags: organization.tags.filter((tagName) => tagName !== tag),
    });
    void utils.organization.invalidate();
  };

  return (
    <ButtonGroup
      className="group"
      sx={{
        "--ButtonGroup-separatorColor": "transparent",
      }}
      variant="outlined"
      color={isHighlighted ? "primary" : "neutral"}
    >
      <Button
        size="lg"
        fullWidth
        className="flex flex-col"
        sx={{
          borderWidth: isHighlighted ? 3 : 1,
        }}
        data-testid={isTestUnit ? ORGANIZATION_NAVIGATION_BUTTON_ID : undefined}
        onClick={() => {
          navigate("/:organizationId", {
            params: {
              organizationId: organization.id,
            },
          });
        }}
      >
        <div className="flex w-full flex-row items-center gap-2">
          <span>{organization.name}</span> <ArrowForward fontSize="small" />
        </div>
        {hasPhase && (
          <div className="flex w-full items-center gap-2">
            <PhaseProgress
              range={range}
              remaining={remaining}
              color="neutral"
              size="sm"
              sx={{ backgroundColor: "rgba(0, 0, 0, .15)" }}
            />
            <Typography level="body-xs">
              {t("trial.daysLeft", { count: Math.max(remaining, 0) })}
            </Typography>
          </div>
        )}
        {organization.tags.length > 0 && (
          <div
            className="mt-2 flex w-full flex-wrap gap-2"
            data-testid={isTestUnit ? ORGANIZATION_TAGS_ID : undefined}
          >
            {organization.tags.map((tag) => (
              <Chip
                key={tag}
                variant="outlined"
                size="sm"
                color="primary"
                className="flex"
                sx={{
                  px: 1.5,
                  py: 0.5,
                }}
                endDecorator={
                  <ChipDelete
                    onDelete={async (e) => {
                      e.stopPropagation();
                      await onDelete(tag);
                    }}
                  />
                }
              >
                {tag}
              </Chip>
            ))}
          </div>
        )}
      </Button>
      <IconButton
        onClick={addTag}
        sx={{
          borderWidth: isHighlighted ? 3 : 1,
        }}
        data-testid={isTestUnit ? ORGANIZATION_TAG_BUTTON_ID : undefined}
      >
        <Tag className={getVisibilityClass(false)} />
      </IconButton>
      <IconButton
        onClick={toggleFavorite}
        sx={{
          borderWidth: isHighlighted ? 3 : 1,
        }}
        data-testid={isTestUnit ? ORGANIZATION_FAVORITE_BUTTON_ID : undefined}
      >
        {isFavorite ? (
          <Bookmark className={getVisibilityClass(isFavorite)} />
        ) : (
          <BookmarkBorder className={getVisibilityClass(isFavorite)} />
        )}
      </IconButton>
    </ButtonGroup>
  );
}
